/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@font-face {
  font-family: Circular;
  src: url("./assets/fonts/circular-light.woff2") format("woff2"), url("./assets/fonts/circular-light.woff2") format("woff");
  font-weight: 300;
  font-style: normal;
}
* {
  font-family: "Circular";
}

.toast-radar {
  top: -50px !important;
}

.toast-button-group {
  height: 300px !important;
  position: relative;
}

.toast-assinatura-botao {
  height: 300px !important;
  position: relative;
}

.icone-menu-fechar {
  position: relative;
  z-index: 999999;
  color: white;
  top: 10px;
  display: block;
  width: 100%;
  text-align: right;
  right: 15px;
}

.icone-menu-topo {
  height: 48.5px;
  width: 48.5px;
  position: relative;
  margin-top: 15.5px;
}

.ios .icone-menu-topo {
  height: 48.5px;
  width: 48.5px;
  position: relative;
  margin-top: 15.5px;
}

.compartilhar {
  position: relative;
  top: 7px;
  left: 5px;
}

.img-center {
  width: 73%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.ios.badge {
  top: 0px;
  position: relative;
  margin-right: 5px;
}

.badge {
  top: 3px;
  position: relative;
  margin-right: 5px;
}

ion-tab-bar {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.ios ion-fab {
  bottom: 10%;
}

.ios.toolbar-segment {
  position: relative;
  top: 0px;
  margin-top: 0px;
  padding-top: 0px;
}

ion-segment-button {
  min-width: auto;
}

.google-chart {
  overflow-y: hidden;
  overflow-x: auto;
}

.top-android {
  position: relative;
  margin-top: 60px;
}

ion-modal.modal-pontuacao::part(content) {
  --height: 90%;
  position: absolute;
  top: 5%;
  --width: 90%;
  border-radius: 25px;
  display: block;
}

ion-icon {
  cursor: pointer;
}